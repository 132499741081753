/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Grid, PseudoBox, Text } from '@chakra-ui/core';
import React, { useMemo, useState } from 'react';
import { KeylessStatus, Tour, TourMap } from '../../../../_generated/types';
import { h1Alt } from '../../../constants/typography';

import { KeyValuePair } from '../index';
import moment from 'moment';
import strings from '../../../constants/strings';
import { useStoreActions } from '../../../redux/hooks';
import { Pill } from '../../Common/Pill';
import { useRouter } from 'next/router';
import { tourIsExpired } from '../../../utils';
import { TourSectionMenuOption, TourSectionOptionsMenu } from './TourSectionOptionsMenu';
import useCanMapBeBlocked from '../../../hooks/useCanMapBeBlocked';

export interface TourSectionProps {
  tour: Tour;
  zIndex: number;
  onClick: () => Promise<boolean | null | undefined>;
  disabled?: boolean;
  setTourMapToBlock: (tourMap: TourMap) => void;
}

const TourSection: React.FC<TourSectionProps> = ({ tour, onClick, zIndex, disabled, setTourMapToBlock }) => {
  const text = strings.TourSection;
  const router = useRouter();
  const tourDate = moment(tour.startTime);
  const agentName = text.agentName(tour);
  const [loading, setLoading] = useState(false);

  const setTourID = useStoreActions(({ prospect }) => prospect.setTourId);
  const setTourIdToRevise = useStoreActions(({ prospect }) => prospect.setTourIdToRevise);

  const { canMapBeBlocked, isBaseMapAdministrator } = useCanMapBeBlocked();

  const onClickHandler = async () => {
    try {
      setLoading(true);
      await onClick();

      setLoading(false);
      router.push(`/tour/${tour.tourId!}`).then();
    } catch (e) {
      setLoading(false);
    }
  };

  // if there is data and the tour has not been completed, it can be deleted
  const canDelete = !!tour?.tourMap?.mapData?.type && !tour.tourMap?.completedAt;
  const tourStatus = strings.TourSection.tourStatus(tour);
  const isExpired = tourIsExpired(tour.tourMap?.completedAt, tour?.startTime);
  const keylessStatus = tour?.tourMap?.keylessStatus;
  const handleKeylessPillProps = () => {
    switch (tour?.tourMap?.keylessStatus) {
      case KeylessStatus.Enabled:
        return { color: 'successGreen', backgroundColor: 'greenOpaque', text: 'Keyless Complete' };
      case KeylessStatus.KeylessError:
        return { color: 'alertRed', backgroundColor: 'redOpaque', text: 'Keyless Failed' };
      case KeylessStatus.KeylessPending:
        return { color: 'alertRed', backgroundColor: 'redOpaque', text: 'Keyless Pending' };
      default:
        return {};
    }
  };
  const hasKeylessEntries = keylessStatus !== KeylessStatus.NotKeyless;
  const isBlockMapEnabled = !tour.tourMap ? false : canMapBeBlocked(tour.tourMap);
  const menuOptions: TourSectionMenuOption[] = useMemo(() => {
    // There can be a scenario where a map is not yet created, in this case the option for blocking shouldn't be in the menu
    const options: TourSectionMenuOption[] = [];
    if (!!tour.tourMap) {
      options.push({
        color: 'alertRed',
        text: text.blockMap(isBlockMapEnabled),
        setId: () => setTourMapToBlock(tour.tourMap!),
        isEnabled: isBlockMapEnabled,
        shouldBeVisible: isBaseMapAdministrator,
      });
    }
    options.push(
      ...[
        {
          color: 'alertRed',
          text: text.deleteText,
          setId: () => setTourID(tour.tourId || ''),
          isEnabled: canDelete,
          shouldBeVisible: true,
        },
        {
          color: 'gray.800',
          text: text.reviseTour,
          setId: () => setTourIdToRevise(tour.tourId || ''),
          isEnabled: tourDate.isSame(moment(), 'date') || !tour.tourMap?.completedAt,
          shouldBeVisible: true,
        },
      ],
    );
    return options;
  }, [isBlockMapEnabled, canDelete, tour.tourMap, isBaseMapAdministrator]);

  return (
    <Flex
      data-cy="TourSection"
      borderRadius="8px"
      backgroundColor="lightGray"
      mx="16px"
      mb="16px"
      justify="space-between"
    >
      <PseudoBox
        display="flex"
        justifyContent="space-between"
        width="100%"
        p="16px 24px"
        mr="4px"
        tabIndex={0}
        _hover={{ textDecoration: 'none', cursor: !disabled ? 'pointer' : undefined }}
        aria-disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onClickHandler().then();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !disabled) {
            onClickHandler().then();
          }
        }}
      >
        <Grid
          w="100%"
          gap="8px"
          gridTemplateColumns="1fr max-content"
          gridTemplateAreas="'topRow rightColumn' 'bottomRow rightColumn'"
        >
          <Flex gridArea="topRow" direction="column">
            <Text as="h1" {...h1Alt}>
              {tour.communityName}
            </Text>
            <Grid
              columnGap="24px"
              autoColumns="max-content"
              templateColumns={{ lg: 'repeat(2, max-content)', xl: 'repeat(4, max-content)' }}
              alignItems="end"
            >
              <Grid columnGap="8px" templateAreas='"a a"'>
                <KeyValuePair label={'date'} value={tourDate.format('MM/DD/YY')} />
              </Grid>
              <Grid columnGap="8px" templateAreas='"a a"'>
                <KeyValuePair label={'time'} value={tourDate.format('h:mma')} />
              </Grid>
              <Grid columnGap="8px" templateAreas='"a a"'>
                <KeyValuePair label={'opportunity owner'} value={agentName} />
              </Grid>
              <Grid columnGap="8px" templateAreas='"a a"'>
                <KeyValuePair
                  label={'Tour ID'}
                  value={(() => {
                    if (tour.isTest) {
                      return tour.tourId!.split('-')[0] + '...';
                    } else {
                      return tour.tourId!;
                    }
                  })()}
                />
              </Grid>
            </Grid>
            <Flex>
              <Pill mt="16px" text={tourStatus} loading={loading} />
              {hasKeylessEntries ? <Pill mt="16px" ml="16px" {...handleKeylessPillProps()} /> : null}
            </Flex>
          </Flex>

          <Flex gridArea="rightColumn" direction="column" justify="center" alignItems="flex-end">
            <Pill text={!tourStatus && !isExpired ? 'New' : ''} loading={loading} />
            <Pill
              mt="16px"
              backgroundColor="blueOpaque"
              color="pathBlue"
              text={strings.TourSection.formatTimeStamp('Began Tour', tour?.tourMap?.beganTourAt)}
            />
            <Pill
              mt="16px"
              backgroundColor="blueOpaque"
              color="pathBlue"
              text={strings.TourSection.formatTimeStamp('Tour Extended', tour?.tourMap?.extendedAt)}
            />
            <Pill
              mt="16px"
              backgroundColor="purpleOpaque"
              color="completePurple"
              text={strings.TourSection.formatTimeStamp('Tour Complete', tour.tourMap?.completedAt)}
            />
            <Pill
              mt="16px"
              backgroundColor="mediumGrayOpaque"
              color="mediumGray"
              text={strings.TourSection.formatTimeStamp(
                'Tour Expired',
                isExpired ? moment(tour.startTime!).hour(17).minute(0).toString() : '',
              )}
            />
          </Flex>

          <Flex gridArea="bottomRow" direction="row">
            <Pill backgroundColor="pinkOpaque" color="lightPink" text={tour.isTest ? 'Test Tour' : undefined} />
          </Flex>
        </Grid>
      </PseudoBox>
      <TourSectionOptionsMenu
        key={tour.tourId}
        zIndex={zIndex}
        menuOptions={menuOptions}
        tourMap={tour.tourMap ?? undefined}
      />
    </Flex>
  );
};

export default TourSection;
